<template>
  <div class="summary-container">
    <div class="import-summary">
      <div class="left-panel">
        <h3>
          Parsed <b>{{ totalParsed || 0 }}</b> rows.
          <b>{{ processed || 0 }}</b> records imported.
          <b>{{ parseInt(totalParsed || 0) - parseInt(processed || 0) }}</b>
          rows failed.
          <ebp-button
            className="btn-sm"
            variant="secondary"
            @click="toggleDetails"
            >{{ detailsVisible ? $t("hide-details") : $t("show-details") }}
          </ebp-button>
        </h3>
        <div class="valueSummaries" v-if="!isDaily">
          <h3 class="valueSummary">
            <span class="valueSummary__label">Total royalty: </span
            ><b class="valueSummary__value">{{
              formatCurrency(totalRoyalty || 0, currency || "GBP")
            }}</b>
          </h3>
          <h3 class="valueSummary">
            <span class="valueSummary__label">Total sales: </span
            ><b class="valueSummary__value">{{ totalSaleCount }}</b>
          </h3>
          <h3 class="valueSummary">
            <span class="valueSummary__label">Total sales value: </span
            ><b class="valueSummary__value">{{
              formatCurrency(totalSales || 0, currency || "GBP")
            }}</b>
          </h3>
          <h3 class="valueSummary">
            <span class="valueSummary__label">Total commission: </span
            ><b class="valueSummary__value">{{
              formatCurrency(totalCommision || 0, currency || "GBP")
            }}</b>
          </h3>
        </div>
        <h3>
          Please review details above and click <b>Confirm</b> to recognize
          these sales.
        </h3>
      </div>
      <div class="right-panel">
        <ebp-button
          :loading="cancelling"
          :disabled="confirming"
          variant="danger"
          @click="cancel"
        >
          {{ $t("cancel") }}
        </ebp-button>
        <ebp-button
          :loading="confirming"
          :disabled="cancelling"
          @click="confirm"
        >
          {{ $t("confirm") }}
        </ebp-button>
      </div>
    </div>
    <transition name="fade">
      <div class="import-results-details" v-if="this.detailsVisible">
        <h4>Errors:</h4>
        <p v-for="item in failedRecords['errors']" :key="item" v-html="item" />
        <h4>Warnings:</h4>
        <p
          v-for="item in failedRecords['warnings']"
          :key="item"
          v-html="item"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "import-summary",
  data() {
    return {
      confirming: false,
      cancelling: false,
      detailsVisible: false
    };
  },
  props: [
    "import_id",
    "failed",
    "processed",
    "totalRoyalty",
    "currency",
    "totalParsed",
    "totalSales",
    "totalCommision",
    "isDaily",
    "totalSaleCount"
  ],
  computed: {
    failedRecords() {
      let json;

      try {
        json = JSON.parse(this.failed);
      } catch (err) {
        console.error(err);
        json = {};
      }

      return json;
    }
  },
  methods: {
    async cancel() {
      this.cancelling = true;

      try {
        await this.$store.dispatch(
          `${this.$props.isDaily ? "dailySaleImports" : "saleImports"}/cancel`,
          this.import_id
        );
        this.$router.push({
          name: `View${this.$props.isDaily ? "Daily" : ""}SaleImports`
        });
      } catch (err) {
        console.error(err);
      }

      this.cancelling = false;
    },
    async confirm() {
      this.confirming = true;

      try {
        await this.$store.dispatch(
          `${this.$props.isDaily ? "dailySaleImports" : "saleImports"}/confirm`,
          this.import_id
        );
        this.$router.push({
          name: `View${this.$props.isDaily ? "Daily" : ""}SaleImports`
        });
      } catch (err) {
        console.error(err);
      }

      this.confirming = false;
    },

    toggleDetails() {
      this.detailsVisible = !this.detailsVisible;
    }
  }
};
</script>

<style lang="scss">
.import-summary {
  position: fixed;
  height: 120px;
  background: rgba(249, 222, 183, 0.93);
  width: 70%;
  left: 20%;
  bottom: 3%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0.4rem 3rem;
  border: solid 1px #f3c789;
  box-shadow: 0 0 5px #ccc;

  h3 {
    font-size: 13px;
    margin: 0;
  }

  .right-panel {
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;

    > * {
      margin-left: 0.8rem;
    }
  }
}

.import-results-details {
  position: fixed;
  bottom: 22%;
  left: 20%;
  display: block;
  height: 35%;
  width: 70%;
  z-index: 100000;
  background: #fff;
  padding: 20px;
  border: solid 1px #ccc;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: 0 0 5px #ccc;

  p {
    margin-bottom: 0.4rem;
  }
}

.valueSummaries {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.valueSummary {
  line-height: 22px;
  padding: 10px 20px 10px 0;

  &__label {
  }

  &__value {
  }
}
</style>
